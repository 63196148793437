<template>
    <div class="aboutUs">
        <div class="swiper-container pageTop_box" id="aboutUsTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination aboutUsTop-pagination"></div>
        </div>

        <!-- 公司介绍 -->
        <div class="box_ aboutUs_2_box">
            <div class="left_ src-left"><div class="bg_img" :style="'background-image: url('+home2.img+')'"></div></div>
            <div class="right_">
                <div class="home_box_title">
                    <span>{{home2.name||'公司介绍'}}</span>
                    <div class="english absol_">INTRODUCTION </div>
                </div>
                <div class="line"></div>
                <div class="info" v-html="home2.content"></div>
            </div>
        </div>

        <!-- 创始人团队 -->
        <div class="box_ aboutUs_3_box">
            <div class="home_box_title">
                <span>{{home3.name||'创始人团队'}}</span>
                <div class="english absol_">CREATION CORPS </div>
            </div>
            <div class="person_list src-bottom-1">
                <div v-for="item in home3.list" :key="item.id">
                    <div class="bg_img"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="name">{{item.title}}</div>
                    <div class="info t_">{{item.excerpt}}</div>
                    <div class="info desc_" v-html="item.content"></div>
                </div>
            </div>
        </div>

        <!-- 发展历程 -->
        <div class="aboutUs_4_box">
            <div class="box_">
                <div class="home_box_title">
                    <span>{{home4.name||'发展历程'}}</span>
                    <div class="english absol_">DEVELOPMENT HISTORY</div>
                </div>
                <img :src="home4.img" class="img_detail src-right">
            </div>
            <div class="bg_bottom_img"></div>
        </div>

        <!-- 企业文化 -->
        <div class="box_ aboutUs_5_box">
            <div class="home_box_title">
                <span>{{home5.name||'企业文化'}}</span>
                <div class="english absol_">CORPORATE CULTURE</div>
            </div>

            <div class="btn-list src-left">
                <div v-for="item in home5.list" :key="item.id">
                    <div class="left_ bg_img"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="right_">
                        <div class="content">
                            <div class="name">{{item.title}}</div>
                            <div class="info">{{item.excerpt}}</div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div class="aboutUs_6_box" >
            <div :style="'background-image: url('+bottomImg+')'"></div>
        </div>

        <footerComponent></footerComponent>
    </div>
</template>
<script>
export default {
    name:'aboutUs',
    data(){
        return{
            banner:[],
            home2:{},
            home3:{
                name:'',
                list:[]
            },
            home4:{},
            home5:{
                name:'',
                list:[]
            },
            bottomImg:''
        }
    },
    created(){
        this.initData()
    },
    methods:{
        initData(){
            this.$Request({
                url: '/portal/about_us',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                this.banner = this.$getListByObj(data.banners).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#aboutUsTop_swiper', {
                            pagination: '.aboutUsTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
                //公司介绍
                let company_introduction = this.$initNes(data.company_introduction);
                this.$set(this, 'home2',{
                    name: company_introduction.name,
                    img: company_introduction.val[0] && company_introduction.val[0].thumbnail,
                    content:  company_introduction.val[0] && company_introduction.val[0].content,
                })
                //创始人团队
                let founder_team = this.$initNes(data.founder_team);
                this.$set(this, 'home3',{
                    name: founder_team.name,
                    list: founder_team.val,
                })
                //发展历程
                let development_course = this.$initNes(data.development_course);
                this.$set(this, 'home4',{
                    name: development_course.name,
                    img: development_course.val[0] && development_course.val[0].thumbnail,
                })
                //企业文化
                let enterprise_culture = this.$initNes(data.enterprise_culture);
                this.$set(this, 'home5',{
                    name: enterprise_culture.name,
                    list: enterprise_culture.val,
                })
                //底部图片
                let bottom = this.$getListByObj(data.bottom)
                this.bottomImg = bottom[0]&&bottom[0].image
                this.setScrol()
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.aboutUs{
    .pageTop_box{
        width: 100%;
        height: 450px;
    }
    .box_{
        width: 1200px;
        margin: 0 auto;
    }
    .aboutUs_2_box{
        margin-top: 70px;
        position: relative;
        min-height: 480px;
        padding-left: 580px;
        .left_{
            position: absolute;
            left: 0;
            top: 0;
            width: 580px;
            height: 480px;
        }
        .bg_img{
            width: 100%;
            height: 100%;
            @include backImg('../assets/img/home_6_1.png');
            background-color: #ccc;
        }
        .right_{
            margin-left: 35px;
            .line{
                margin-top: 30px;
                width: 120px;
                height: 4px;
                background: $lv;
            }
            .info{
                margin-top: 40px;
                font-size: 14px;
                color: #333333;
                line-height: 32px;
                height: 340px;
                overflow-y: auto;
            }
        }
    }
    .aboutUs_3_box{
        margin-top: 73px;
        .person_list{
            margin-top: 35px;
            display: inline-block;
            width: 100%;
            &>div{
                float: left;
                width: 380px;
                height: 614px;
                overflow-y: hidden;
                background: #FFFFFF;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                margin-bottom: 20px;
                padding-bottom: 20px;
                transition: all .3s ease;  
                
                &:not(:nth-child(4n)){
                    margin-right: 15px;
                }
                .bg_img{
                    width: 100%;
                    height: 380px;
                    overflow: hidden;
                    &>div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #ccc;
                    }
                } 
                .name{
                    padding: 0 15px;
                    color: $lv;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 10px 0 0 0;
                }
                .info{
                    padding: 0 15px;
                    font-size: 14px;
                    color: #6C6C6C;
                    line-height: 21px;
                    &.desc_{
                        margin-top: 12px;
                    }
                }
                &:hover{
                    transform: translateY(-15px);
                }
            }
        }
    }
    .aboutUs_4_box{
        margin-top: 60px;
        padding-bottom: 20px;
        position: relative;
        z-index: 0;
        padding: 1px 0 70px;
        .img_detail{
            margin-top: 60px;
            width: 100%;
            display: block;
        }
        .bg_bottom_img{
            position: absolute;
            width: 100%;
            height: 934px;
            left: 0;
            bottom: -20px;
            z-index: -1;
            @include backImg('../assets/img/aboutus_4_1.png');
        }
    }
    .aboutUs_5_box{
        .btn-list{
           margin-top: 35px;
           display: inline-block;
           width: 100%;
           &>div{
                width: 588px;
                height: 164px;
                background: #FFFFFF;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                position: relative;
                padding-left: 294px;
                float: left;
                margin-bottom: 24px;
                &:not(:nth-child(2n)){
                    margin-right: 24px;
                }
                .left_{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 294px;
                    height: 100%;
                }
                .bg_img{
                    overflow: hidden;
                    >div{
                        width: 100%;
                        height: 100%;
                        background-color: #ccc;
                        @include backImg('../assets/img/home_6_1.png');
                        transition: all .3s ease;  
                    }
                    
                }
                .right_{
                    .content{
                        position: absolute;
                        top: 50%; /*偏移*/
                        transform: translateY(-50%);
                        right: 20px;
                        width: 250px;
                    }
                    .name{
                        font-size: 20px;
                        color: #333333;
                        line-height: 30px;
                    }
                    .info{
                        margin-top: 5px;
                        font-size: 14px;
                        color: #999999;
                        line-height: 21px;
                    }
                }
                &:hover{
                    .bg_img>div{
                        transform: scale(1.1);
                    }
                }
           } 
        }
    }
    .aboutUs_6_box{
        margin-top: 60px;
        width: 100%;
        height: 280px;
        >div{
            width: 100%;
            height: 100%;
            @include backImg('../assets/img/home_6_1.png');
        }
    }
}
</style>